import { ImgSrc } from "src/interfaces/ImgSrc";
import { TPartialSocials } from "./socials";

const artistsId = [
  "kepinski-i-kowalonek",
  "duss"
  // , "cd-projekt-red"
] as const;
export type ArtistsId = (typeof artistsId)[number];

export interface IArtist {
  name: string;
  description: string;
  logoSrc: ImgSrc;
  bgSrc: ImgSrc;
  socials: TPartialSocials;
}

const kepinskiIKowalonek: IArtist = {
  name: "Kępiński & Kowalonek",
  description:
    "This exceptional duo has already gained some extensive stage experience on the Polish music scene. Both the guys tend to create very moving, mesmerising and electrifying songs that combine classic electronic indie mixed with synth-pop sounds, therefore resulting in eclectic and unique music.",
  socials: {
    ig: "https://www.instagram.com/kepinski_kowalonek/",
    yt: "https://www.youtube.com/channel/UCdiW66TtyfZWWlASPStPZzw",
    spotify: "https://open.spotify.com/artist/3z84Fsj4Ob6F940y1FwXnk",
    fb: "https://www.facebook.com/Kepinski.Kowalonek/"
  },
  bgSrc: "/images/music/artists/kepinski-i-kowalonek/bg.avif",
  logoSrc: "/images/music/artists/kepinski-i-kowalonek/logo.svg"
};

const duss: IArtist = {
  name: "Duss",
  description:
    "This limited edition vinyl merges pulsating rhythms with advanced NFT/NFC technology, providing a unique experience. The Go2NFT solution introduces new, never-before-seen features. ",
  socials: {
    ig: "https://www.instagram.com/duss.official/?hl=en",
    "sound-cloud": "https://soundcloud.com/dussmusic",
    fb: "https://www.facebook.com/DUSS.Music/about"
  },
  bgSrc: "/images/music/artists/duss/bg.jpg",
  logoSrc: "/images/music/artists/duss/logo.svg"
};

const cdProjektRed: IArtist = {
  name: "CD PROJEKT RED",
  description: "Decription",
  bgSrc: "/images/music/artists/cd-projekt-red/bg.jpg",
  logoSrc: "/images/music/artists/cd-projekt-red/logo.svg",
  socials: {
    fb: "https://www.facebook.com/CDPROJEKTRED/",
    ig: "https://www.instagram.com/cdprojektred/?hl=en",
    x: "https://x.com/CDPROJEKTRED",
    yt: "https://www.youtube.com/channel/UCuCtanBJXOPAEIAUXf9944g"
  }
};

export const artists: Record<ArtistsId, IArtist> = {
  "kepinski-i-kowalonek": kepinskiIKowalonek,
  duss: duss
  // "cd-projekt-red": cdProjektRed
};

export const objectHasOwn = (obj: any, key: string | undefined) => !!obj[key as any];
export const getArtistById = (artistById: string | undefined): IArtist | undefined => {
  if (!objectHasOwn(artists, artistById)) return undefined;

  return (artists as any)[artistById!];
};
