import { ProductsId } from "./products";

export interface IYtVideo {
  img?: string;
  title?: string;
  description?: string;
  url: string;
}

export interface SoundCloudItem {
  url: string;
  description: string;
}

export interface IExtra {
  extraDescription: string;
  photos?: string[];
  soundClouds?: SoundCloudItem[];
  ytVideos?: IYtVideo[];
}

export const extras: Record<ProductsId, IExtra> = {
  kintsugi: {
    extraDescription:
      "We are glad that you have purchased the “Kintsugi” vinyl. It is our contribution to the universe. You now have it at hand. Together with GO2NFT, we have prepared a surprise for you. You are one of the first people to receive access to the premiere publication of the concert of the KĘPIŃSKI >|< KOWALONEK duo. It was captured during the Nextfest showcase festival at Scena na Piętrze (in 2023). This is our most essential moment, and we want to share it with you.\nThank you for being with us!",
    ytVideos: [
      {
        url: "https://bio.to/UniverseOfOurs",
        img: "/images/music/artists/kepinski-i-kowalonek/extras/yt/secret-1.jpg",
        title: "KĘPIŃSKI KOWALONEK - Live at NEXTFEST 2023",
        description:
          "Premierowy koncert  duetu KĘPIŃSKI  |  KOWALONEK  został zarejestrowany w ramach NextFest Showcase Conference w Poznaniu."
      }
    ]
  },
  "bring-the-attention-to-the-body": {
    extraDescription:
      "Thank you for your interest in the Bring The Attention To The Body vinyl record. In cooperation with Go2NFT, we have prepared a special surprise for you - four exclusive tracks by DUSS, available exclusively to owners of our promotional vinyl.",
    soundClouds: [
      {
        description: "Duss - Bring The Attention To The Body",
        url: "https://soundcloud.com/dussmusic/duss-bring-the-attention-to-the-body-1/s-gVJJ7TGomij?si=tak%C5%BCe%20&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
      },
      {
        description: "Duss - First Twin",
        url: "https://soundcloud.com/dussmusic/duss-first-twin-10/s-FlBfw8U7YRn?si=e3b2019080274739b768b63cafee0df5&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
      },
      {
        description: "Duss @ Audioriver 2024",
        url: "https://soundcloud.com/dussmusic/duss-audioriver-2024/s-1p2Y8vy6tGS?si=e3b2019080274739b768b63cafee0df5&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
      },
      {
        description: "Duss @ Wisłoujście 2023",
        url: "https://soundcloud.com/dussmusic/duss-wisloujscie-2024/s-DX06X19Z2G6?si=e3b2019080274739b768b63cafee0df5&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
      }
    ]
  }
  // "male-v-figure": {
  //   extraDescription:
  //     "Here, you will unlock additional premium content for the collectible figure from the Cyberpunk 2077 game.",
  //   photos: [
  //     "/images/music/artists/cd-projekt-red/extras/photos/1.jpg",
  //     "/images/music/artists/cd-projekt-red/extras/photos/2.jpg",
  //     "/images/music/artists/cd-projekt-red/extras/photos/3.jpg",
  //     "/images/music/artists/cd-projekt-red/extras/photos/4.jpg"
  //   ],
  //   ytVideos: [
  //     {
  //       url: "https://www.youtube.com/watch?v=u15tEo0wsQI",
  //       img: "https://i.ytimg.com/vi/u15tEo0wsQI/hq720.jpg?sqp=-oaymwEpCNAFEJQDSFryq4qpAxsIARUAAIhCGAHYAQHiAQwIGBACGAYgATgBQAE=&rs=AOn4CLAdZUbnLw9gR3arM7KJQwFvWrDotA",
  //       title: "Dawid Podsiadło, P.T. Adamczyk — Phantom Liberty (Official Cyberpunk 2077 Music Video)"
  //     }
  //   ]
  // }
};
